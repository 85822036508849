import React, {useEffect} from "react";
import {useForm} from "antd/es/form/Form";
import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Image,
    Input,
    message,
    notification,
    Row, Select,
    Spin
} from "antd";
import {useMutation, useQuery} from "@apollo/client";
import {GET_PERSON_DETAILS} from "../queries.graphql";
import {SAVE_PERSON} from "./queries.graphql";
import {useNavigate, useParams} from "react-router-dom";
import {RoleSelector} from "../../../components/roles/selector";
import {MaskedInput} from "antd-mask-input";
import {REQUIRED_RULE, RUSSIAN_PATTERN_RULE} from "../utils";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment, {Moment} from "moment";
import {Person} from "../../../model/person";
import {isEmpty} from "../../../utils";

export interface EditUserInfoViewProps {
    id?: string
}

const INITIAL_DATA = {
    personType: "EMPLOYEE",
    canAuth: true,
    accountInfo: {
        iconUrl: "",
        roles: ["SPECIALIST"]
    }
}

const PROFILE_LOCKED = false;
const PROFILE_ACTIVE = true;

export const EditUserInfoView = ({id}: EditUserInfoViewProps) => {
    const {mode} = useParams<{ id: string, mode: string }>()
    const [form] = useForm();
    const history = useNavigate();
    const {loading, data} = useQuery<{ person: Person }>(GET_PERSON_DETAILS, {
        skip: id === undefined,
        variables: {
            id
        }
    })

    const [saveUserInfoMutation, {error, loading: saving}] = useMutation(SAVE_PERSON)

    useEffect(() => {
        form.setFieldsValue({
            ...INITIAL_DATA,
            ...data?.person,
            birthday: data?.person?.birthday != null ? moment(data.person.birthday, "YYYY-MM-DD"): null
        })
    }, [data])

    const saveUserInfo = (customProps = {}) => {
        saveUserInfoMutation({
            variables: {
                employee: {
                    ...form.getFieldsValue(),
                    accountInfo: {
                        ...form.getFieldsValue().accountInfo,
                        owner: { id: form.getFieldsValue().id || null},
                        login: form.getFieldsValue().phoneNumber
                    },
                    ...customProps,
                }
            }
        }).then(() => {
            message.success("Сохранено")
            history(-1)
        }).catch(console.error)
    }

    useEffect(() => {
        if (error?.message) {
            notification
                .error({message: "При сохранении возникли ошибки", description: error?.message, duration: 10})

            form.setFields([
                {name: "login", errors: ["Пользователь с таким номером телефона уже существует"]}
            ])
        }
    }, [error, saving])

    const saveForm = () => form.validateFields().then(() => saveUserInfo({}))
        .catch(reason => notification.error({message: "Введенные данные некоректны."}))

    return (
        <Spin spinning={loading || saving}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card title={"Редактирование пользователя"}
                          extra={(<Button onClick={saveForm} type={"link"}>Сохранить</Button>)}>
                        <Form form={form} layout={"vertical"} initialValues={INITIAL_DATA}>
                            <Col span={24}>
                                <Row gutter={16}>
                                    <Col span={3}>
                                        <Col span={24} style={{textAlign: "center"}}>
                                            <Image preview={false}
                                                   style={{maxHeight: 300}}
                                                   src={data?.person.accountInfo.iconUrl || "/img/profile_img.png"}/>
                                        </Col>
                                    </Col>
                                    <Form.Item name={"id"} hidden><Input/></Form.Item>
                                    <Form.Item name={"personType"} hidden><Input/></Form.Item>
                                    <Col span={21}>
                                        <Row gutter={[16, 16]}>
                                            <Col span={6}>
                                                <Form.Item name={"lastName"} label={"Фамилия"}
                                                           rules={[REQUIRED_RULE, RUSSIAN_PATTERN_RULE]}>
                                                    <Input placeholder={"Введите фамилию"}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item name={"name"} label={"Имя"}
                                                           rules={[REQUIRED_RULE, RUSSIAN_PATTERN_RULE]}>
                                                    <Input placeholder={"Введите имя"}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item name={"surName"} label={"Отчество"}
                                                           rules={[REQUIRED_RULE, RUSSIAN_PATTERN_RULE]}>
                                                    <Input placeholder={"Введите отчество"}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item label={"Дата рождения"} name={"birthday"} rules={[REQUIRED_RULE]}>
                                                    <DatePicker format={"DD.MM.YYYY"} placeholder={"Дата рождения"}
                                                                locale={locale} value={moment(form.getFieldValue("birthday"), "YYYY-MM-DD")}
                                                                disabledDate={(date: Moment) => date.isAfter(moment())}
                                                                style={{width: "100%"}}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Row gutter={[16,16]}>
                                                    <Col span={8}>
                                                        <Form.Item name={["accountInfo","canAuth"]} label={"Состояние профиля"}
                                                                   required
                                                        >
                                                            <Select defaultValue={true}>
                                                                <Select.Option value={PROFILE_ACTIVE}>Активный</Select.Option>
                                                                <Select.Option value={PROFILE_LOCKED}>Заблокирован</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item name={"phoneNumber"} label={"Номер телефона"}
                                                                   rules={[REQUIRED_RULE]}>
                                                            <MaskedInput mask={"+7 000 000 0000"}
                                                                         placeholder={"Введите номер телефона"}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Form.Item name={["accountInfo","id"]} hidden ><Input /></Form.Item>
                                            <Form.Item name={["accountInfo","owner"]} hidden ><Input /></Form.Item>
                                            <Form.Item name={["accountInfo","login"]} hidden ><Input /></Form.Item>
                                            <Col span={8}>
                                                <Form.Item name={["accountInfo","password"]} label={"Пароль"} rules={[REQUIRED_RULE]}>
                                                    <Input.Password placeholder={"Введите пароль пользователя"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={["accountInfo","roles"]} label={"Роль пользователя"}
                                                           rules={[REQUIRED_RULE]} >
                                                    <RoleSelector placeholder={"Выберите роли пользователя"}
                                                                  disabled={!isEmpty(data?.person?.id)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label={"Ссылка на изображение аватара"}
                                                           name={["accountInfo","iconUrl"]}
                                                           hidden >
                                                    <Input placeholder={"Введите ссылку на изображение пользователя"}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name={"description"} label={"Краткое описание"}>
                                                    <Input.TextArea placeholder={"Доп. сведения"}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Spin>
    )
}