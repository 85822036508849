import React from "react"
import {useQuery} from "@apollo/client";
import {GET_TIMESHEET} from "./timesheet/query.graphql";
import 'moment/locale/ru';
import {TIMESHEET_INITIAL} from "./utils";
import Timesheet from "../../model/timesheet";
import {useParams} from "react-router-dom";
import {TimesheetProvider} from "./context";
import {MenuTabs} from "./tabs";

const TimesheetPage = () => {
    const params = useParams<{ id: string }>()
    const {
        loading,
        data = {timesheet: TIMESHEET_INITIAL}
    } = useQuery<{ timesheet: Timesheet }>(
        GET_TIMESHEET, {
            variables: {id: params.id},
            skip: params.id === "new"
        }
    );

    const timesheetContextDefaultValue = {
        timesheet: data.timesheet,
        loading,
        limits: [],
        date: data.timesheet.date,
        favor: data.timesheet.defaultFavor,
        specialist: data.timesheet.specialist,
        location: data.timesheet?.location,
        errors: new Array<string>(),
        warnings: new Array<string>(),
        status: data.timesheet.status
    }

    return (
        <TimesheetProvider value={timesheetContextDefaultValue}>
            <MenuTabs/>
        </TimesheetProvider>
    )
}

export default TimesheetPage;