import React from "react";
import {Button, Empty, Select, Spin} from "antd";
import {useLazyQuery, useQuery} from "@apollo/client";
import {GET_BATCH, GET_CLIENTS_AND_GROUPS_FOR_TIMESHEET} from "./query.graphql";
import Client from "../../../model/client";
import Batch from "../../../model/batch";
import {Link, useLocation} from "react-router-dom";
import {SelectProps} from "antd/lib/select";
import {isEmpty} from "../../../utils";
import {useTimesheetContext} from "../context";

export enum OptionType {
    CLIENT = "CLIENT", BATCH = "BATCH"
}

interface ClientSelectorProps extends SelectProps{
    placeholder: string
    showBatch?: boolean,
    onSelect: (clients: Client[]) => void
    onChange?: (values: string[] | string, some: any) => void
    exceptClients: Client[]
    filter: any
    value?: string[]
    optionsTypes?: OptionType[]
}

const DEFAULT_PROPS: ClientSelectorProps = {
    placeholder: "",
    exceptClients: [],
    value: [],
    filter: {},
    onSelect: () => { },
    showBatch: true,
    optionsTypes: [OptionType.CLIENT]
}

const TimesheetClientSelector = (props: ClientSelectorProps = DEFAULT_PROPS) => {
    const location = useLocation();
    const sss = useTimesheetContext();
    const {
        loading,
        data = {clients: [],clientsForTimesheet: [], batchesForTimesheet: []}
    } = useQuery<{ clients: Client[], clientsForTimesheet: Client[], batchesForTimesheet: Batch[] }>(GET_CLIENTS_AND_GROUPS_FOR_TIMESHEET, {
        variables: {
            timesheet: Number.parseInt(props.filter.timesheet)
        }, pollInterval: 10000, skip: isEmpty(props.filter.timesheet)
    });

    const [findBatch] = useLazyQuery<{ batch: Batch }>(GET_BATCH, {
        onCompleted: ({batch: {clients}}) => {
            if (clients === undefined || clients.length === 0) return
            else {
                const ids = props.exceptClients.map(({id}) => id);
                props.onSelect(clients.filter(({id}) => !ids.includes(id)));
            }
        }
    });

    const select = (value: any, option: any) => {
        if (option.type === OptionType.BATCH) {
            findBatch({
                variables: {id: option.recordId},
            })
        } else {
            props.onSelect(data.clientsForTimesheet.filter(client => client.id === option.recordId))
        }
    }

    const notFoundContent = (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
               children={(<Link to={{pathname: `/clients/create`}} state={{backgroundLocation: location, timesheet: {id: Number.parseInt(props.filter.timesheet)}}}>
                   <Button type={"link"}>Создать карточку нового клиента</Button></Link>)}
        />
    )


    const options = []

    if ((props.optionsTypes || [OptionType.CLIENT])?.includes(OptionType.CLIENT)) {
        options.push(
            <Select.OptGroup label={"Клиенты"} key={`sel_opt_group_clients}`}>
                {
                    data.clientsForTimesheet
                        .filter(value => !props.exceptClients.map(_ => _.id).includes(value.id))
                        .map(({id, fio, meta}) => (
                            <Select.Option key={`client_${id}`}
                                           recordId={id}
                                           type={OptionType.CLIENT}
                                           value={id}
                                           children={fio}
                                           disabled={(meta||[]).includes("disabled")}
                            />
                        ))
                }
            </Select.OptGroup>
        )
    }
    if (props.optionsTypes?.includes(OptionType.BATCH)) {
        options.push(
            <Select.OptGroup label={"Группы"} key={`sel_opt_group_batches}`}>
                {
                    data.batchesForTimesheet
                        .map(({id, name, description, clients}) => (
                            <Select.Option key={`batch_${id}`}
                                           recordId={id}
                                           type={OptionType.BATCH}
                                           value={`${name} (${(clients || []).length} чел. ${description || ""})`}
                                           children={`${name} (${(clients || []).length} чел. ${description || ""})`}
                            />
                        ))
                }
            </Select.OptGroup>
        )
    }

    return (
        <Spin spinning={loading}>
            <Select showSearch
                    onChange={select} value={props.value?.map(({id}:any) => id)}
                    filterOption={true}
                    placeholder={props.placeholder !== undefined ? props.placeholder : "Выберите клиента"}
                    style={{width: "100%"}} notFoundContent={notFoundContent}
                    optionFilterProp={"children"}
            >
                {options}
            </Select>
        </Spin>
    )
};

export default TimesheetClientSelector